import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from "../service/api.service";
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from "../service/cart.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../alert/alert.component';
@Component({
  selector: 'app-categoryproduct',
  templateUrl: './categoryproduct.component.html',
  styleUrls: ['./categoryproduct.component.css']
})
export class CategoryproductComponent implements OnInit {
  public category_id;
  public response : any = [];
  public products : any = [];
  public notFoundMessage = "";
  // Paginations
  public total_number_of_products : any = 0;
  public number_of_column_to_show : any = 0;
  public page : any = 1;
  public number_of_products_to_show : any = 6;
  public spinner : boolean = false;
  
  // Attributes...
  public attributes : any = [];
  public updatedAttributes : any = [];
  selected_attribute  : any = "";
  public subcategory :any = [];


  constructor( private _snackBar: MatSnackBar, private router: Router, private ApiService: ApiService, private ActivatedRoute:ActivatedRoute, private CartService:CartService) { 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void {
    this.category_id = this.ActivatedRoute.snapshot.paramMap.get('id');
    this.getCategoryWiseProduct();
    this.getSubcategory();
  }

  getCategoryWiseProduct = (attribute_value?:any) =>{
    let attr_filter = "";
    if(attribute_value != undefined){
      attr_filter = `&attribute_value=${attribute_value}`;
    }
    this.spinner = true;
    this.ApiService.getData(`product/byCategory/${this.category_id}?page=${this.page}${attr_filter}`).subscribe((data:any)=>{
      this.response = data;
      this.products = this.response.products;
      this.addQuantity();
      this.attributes = this.response.attributes;
      this.arrangeAttributes();
      this.getPaginationDetails(this.response.totalNumberOfProducts);
      this.notFoundMessage = "Products Not Found";
      this.spinner = false;
    });
  }
  onChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const selectedValue = target.value; // Get the selected value
    if(!selectedValue){
      this.router.navigate([`/category-product/${this.category_id}`]);
    }
    this.router.navigate([`/subcategory-product/${selectedValue}`]);
  }
  getSubcategory = ()=>{
    try {
        this.ApiService.getData('get-menu').subscribe((data:any)=>{
           this.response = data;
           this.response.data.forEach((elem:any) => {
            if(elem._id == this.category_id){
              this.subcategory = elem.subcategories;
              console.log(this.subcategory);
            }
          });
        });
    } catch (error) {
        console.log(error);
    }
  }

  addQuantity = () =>{
    this.products.forEach((element, id) => {
      Object.assign(element, { quantity : 1 });
    });
  }

  getPaginationDetails = (total_products:any) =>{
    try {
        this.number_of_column_to_show = total_products / this.number_of_products_to_show;
        this.number_of_column_to_show = Math.round(this.number_of_column_to_show);
    } catch (error) {
        console.log(error);
    }
  }

  changePage = (page?:any) =>{
    try {
        this.page = page;
        this.getCategoryWiseProduct();
    } catch (error) {
      console.log(error);
    }
  }

  previousPage = () =>{
    try {
      this.page--;
      this.getCategoryWiseProduct();
    } catch (error) {
      console.log(error);
    }
  }

  nextPage = () =>{
    try {
      this.page++;
      this.getCategoryWiseProduct();
    } catch (error) {
      console.log(error);
    }
  }
  
  getAttributesValue = () =>{
    this.getCategoryWiseProduct(this.selected_attribute); 
  }

  removeFilter = () =>{
    this.selected_attribute = "";
    this.getCategoryWiseProduct();
  }

  arrangeAttributes = () =>{
    if(this.attributes != ''){
      this.attributes.forEach((attr, attr_index) => {
        
        let check = 0;
        this.updatedAttributes.forEach(test => {
          if(test.attribute_name == attr.attribute_name){
            check = 1; // the attribue is already exist
          }
        });
        if(check == 0){ // If the attribue is not already exist
          this.updatedAttributes[attr_index] = {attribute_name : attr.attribute_name, attribute_values : []};

          // Adding Attribute Values...
          this.attributes.forEach((subAttr) => {
            if(subAttr.attribute_name == attr.attribute_name){
              this.updatedAttributes[attr_index].attribute_values.push(subAttr.attribute_value);
            }
          });
        }

      });

      // Removing the empty objects 
      this.updatedAttributes = this.updatedAttributes.filter(value => Object.keys(value).length !== 0);
    }
  }

  addToCart = (product:any) =>{
    this.CartService.addToCart(product);
    this.openSnackBar('Cart Updated !');
  }

  openSnackBar(message) {
    this._snackBar.openFromComponent(AlertComponent, {
      duration: 5 * 1000,
      data: {message}
    });
  }
  
}