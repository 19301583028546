<div class="wrapper">
    <!-- Scroll to Top -->
    <div class="scrolltotop">
        <a class="button-circle button-circle-sm button-circle-black" href="#">
            <i class="ti-arrow-up"></i>
        </a>
    </div>
    <!-- end Scroll to Top -->
    <!-- Header -->
    <div class="header header-lg">
        <div class="container">
            <div class="logo">
                <!-- <h4 class="uppercase letter-spacing-2"><a href="#">Studio Kanchie</a></h4> -->
                <a routerLink="/">
                    <img class="logo-dark" src="assets/images/logoParrot.png" alt="">
                </a>
                <!--						
                  <img class="logo-light" src="assets/images/your-logo-light.jpg" alt="">
                  -->
            </div>
            <div class="header-menu-wrapper"  [ngStyle]="{'transform': 'translateX(' + position + 'px)'}">
                <!-- Menu -->
                <ul class="header-menu" #dropdownMenu>
                    <li class="m-item">
                        <a class="m-link" routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="m-item has-dropdown" [class.active]="isDropdownOpen" (click)="toggleMenu($event)">
                        <a class="m-link" style="position: relative;">
                            Shop <span class="icon-arrow-down"></span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-hidden">
                            <div class="menu">
                                <div class="menu-item"  *ngFor="let item of  menu; let i = index;" (click)="toggleSubMenu($event,  i)">
                                    <div class="menu-label" >
                                        <a style="color: #000; display: inline-block;" routerLink="/category-product/{{ item._id }}">
                                            {{ item.name }}
                                        </a>
                                    </div>
                                    <div class="submenu" *ngIf="item.subcategories.length">
                                    <div class="submenu-item" *ngFor="let subItem of item.subcategories" >
                                        <a  style="color: #000; display:block;" routerLink="/subcategory-product/{{ subItem._id }}">
                                            {{ subItem.name }}
                                        </a>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </li>
                    <li class="m-item" *ngFor="let category of categories">
                        <a class="m-link" routerLink="/category-product/{{ category._id }}">
                            {{ category.name }}
                        </a>
                    </li>
                </ul>
                <!-- Extra -->
                <div class="header-menu-extra">
                    <ul class="list-inline">
                        <!-- <li><a href="#"><i class="fas fa-search"></i></a></li> -->
                        <li>
                            <a [routerLink]="token == null ? '/login' : '/profile'">
                                <i class="fas fa-user"></i>
                            </a>
                        </li>
                        <li>
                            <a class="cartcountic" routerLink="/cart"><i class="fas fa-shopping-cart"></i>
                                <div class="cartcount">
                                    <span class="count">{{ cartInformation.length }}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- Close Button -->
                <button class="close-button" (click)="menuToggleMobile(false)">
                    <span></span>
                </button>
            </div><!-- end header-menu-wrapper -->
            <!-- Menu Toggle on Mobile -->
            <button class="m-toggle" (click)="menuToggleMobile(true)">
                <span></span>
            </button>
        </div><!-- end container -->
    </div>
    <!-- end Header -->

    <!-- Hero section -->
    <!-- <div class="owl-carousel owl-dots-overlay" data-owl-items="1" data-owl-autoplay="true">

          <div class="section-2xl bg-image" data-bg-src="assets/images/slider01.jpg">
              <div class="bg-dark-02">
                  <div class="container text-center">
                      <div class="row">
                          <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                              <h6 class="uppercase font-weight-medium margin-bottom-20">Save up to 50%</h6>
                              <h1 class="display-4 font-weight-semi-bold">Clearance Sale!</h1>
                              <a class="button button-lg button-outline-white-2 button-border-2 margin-top-20" href="#">Shop Now</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="section-2xl bg-image" data-bg-src="assets/images/slider02.jpg">
              <div class="bg-dark-02">
                  <div class="container text-center">
                      <div class="row">
                          <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                              <h6 class="uppercase font-weight-medium margin-bottom-20">Save up to 50%</h6>
                              <h1 class="display-4 font-weight-semi-bold">Clearance Sale!</h1>
                              <a class="button button-lg button-outline-white-2 button-border-2 margin-top-20" href="#">Shop Now</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> -->

    <router-outlet></router-outlet>

    <!-- Footer -->
    <div class="section-xs section-ftr">
        <div class="container">
            <div class="row col-spacing-20">
                <div class="col-6 col-lg-3">
                    <h4 class="font-weight-normal margin-lg-bottom-20">About us</h4>
                    <ul class="list-dash">
                        <li><a routerLink="/ourstory">Our Story</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="/career">Careers</a></li>
                        <!-- <li><a href="#">Delivery & Shipping</a></li> -->
                    </ul>
                </div>
                <div class="col-6 col-lg-3">
                    <h4 class="font-weight-normal margin-lg-bottom-20">Useful links</h4>
                    <ul class="list-dash">
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/size-guide">Size Guide</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/term-condition">Terms & Conditions</a></li>
                    </ul>
                </div>
                <div class="col-6 col-lg-3">
                    <h4 class="font-weight-normal margin-lg-bottom-20">Shop</h4>
                   <div class="row">
                    <div class="col-md-6">
                        <ul class="list-dash">
                        
                            <p *ngFor="let subcate of subcategory; let i = index;">
                                <li *ngIf=" i < 6">
                                    <a routerLink="/category-product/{{ subcate._id }}">{{subcate.name}}</a>
                                </li>
                            </p>
                            <!-- <p *ngFor="let category of categories">
                                <li *ngIf="category.name == 'Suits'">
                                    <a routerLink="/category-product/{{ category._id }}">Suits</a>
                                </li>
                            </p>
                            <p *ngFor="let category of categories">
                                <li *ngIf="category.name == 'Jewellery'">
                                    <a routerLink="/category-product/{{ category._id }}">Jewellery</a>
                                </li>
                            </p> -->
                            <!-- <li><a routerLink="/categories">Patialas</a></li>
                            <li><a routerLink="/categories">Kurtis</a></li>
                            <li><a routerLink="/categories">Dresses</a></li> -->
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul class="list-dash">
                        
                            <p *ngFor="let subcate of subcategory; let i = index;">
                                <li *ngIf=" i > 6 && i < 12">
                                    <a routerLink="/category-product/{{ subcate._id }}">{{subcate.name}}</a>
                                </li>
                            </p>
                            <!-- <p *ngFor="let category of categories">
                                <li *ngIf="category.name == 'Suits'">
                                    <a routerLink="/category-product/{{ category._id }}">Suits</a>
                                </li>
                            </p>
                            <p *ngFor="let category of categories">
                                <li *ngIf="category.name == 'Jewellery'">
                                    <a routerLink="/category-product/{{ category._id }}">Jewellery</a>
                                </li>
                            </p> -->
                            <!-- <li><a routerLink="/categories">Patialas</a></li>
                            <li><a routerLink="/categories">Kurtis</a></li>
                            <li><a routerLink="/categories">Dresses</a></li> -->
                        </ul>
                    </div>
                   </div>
                </div>
                <div class="col-6 col-lg-3 text-lg-right">
                    <p class="">28 Jalan Chengkek, Singapore, Singapore<br>{{'kanchie56@gmail.com'}}</p>
                    <ul class="list-inline-sm margin-top-10 margin-lg-top-20">
                        <li><a class="button-circle button-circle-xs button-circle-white"
                                href="https://www.facebook.com/studiokanchi?mibextid=ZbWKwL"><i
                                    class="fab fa-facebook-f"></i></a></li>
                        <li><a class="button-circle button-circle-xs button-circle-white"
                                href="https://instagram.com/studiokanchana?igshid=MzRlODBiNWFlZA=="><i
                                    class="fab fa-instagram"></i></a></li>
                        <li><a class="button-circle button-circle-xs button-circle-white" href="#"><i
                                    class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div><!-- end row -->
            <div class="border-top margin-top-30 padding-y-20 padding-bottom-0">
                <div class="row align-items-center">
                    <div class="col-6">
                        <p>&copy;
                            <script>document.write(new Date().getFullYear())</script> Studio Kanchana
                        </p>
                    </div>
                    <div class="col-6 text-right">
                        <p>Crafted by SAIntellectSolutions</p>
                    </div>
                </div><!-- end row -->
            </div>
        </div><!-- end container -->
    </div>
    <!-- end Footer -->

</div><!-- end wrapper -->